<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <discovery-bio-panel
          v-if="discovery"
          :data="discovery"
          :handle-update="updateData"
        ></discovery-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="discoveryTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="discoveryTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="discovery"
            value="overview"
          >
            <discovery-tab-overview :data="discovery"></discovery-tab-overview>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline } from '@mdi/js'
import { useRouter } from '@core/utils'

import useDiscoveryView from './useDiscoveryView'
import DiscoveryBioPanel from './discovery-bio-panel/DiscoveryBioPanel.vue'
import DiscoveryTabOverview from './discovery-tab-overview/DiscoveryTabOverview.vue'

export default {
  components: {
    DiscoveryBioPanel,
    DiscoveryTabOverview,
  },
  setup() {
    const discoveryTab = ref(null)
    const { route } = useRouter()

    const {
      discovery,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useDiscoveryView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
    ]

    provide('discoveryUpdating', updating)
    provide('discoveryErrorMessages', errorMessages)

    return {
      tabs,
      discoveryTab,

      discovery,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
